import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import bgImage from 'common/assets/image/charity/milestone-bg.png';


export const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 1440px) {
    margin: 0 auto;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 auto;
  }
  @media only screen and (max-width: 991px) {
    margin: 0 auto;
  }
  @media only screen and (max-width: 480px) {
    margin: 0 auto;
  }
`;

export const CounterItem = styled.div`
  text-align: center;
  @media only screen and (max-width: 480px) {
    margin-bottom: 30px;
  }

  h3 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 300;
    @media only screen and (max-width: 1440px) {
      font-size: 40px;
      line-height: 46px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 34px;
      line-height: 40px;
      margin-bottom: 13px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 0;
    @media only screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 1360px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 15px;
    }
  }
`;


