import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/theme/saas';
import { ResetCSS } from 'common/assets/css/style';
import { GlobalStyle, ContentWrapper } from 'containers/Saas/saas.style';
import Navbar from 'containers/Saas/Navbar';
import BannerSection from 'containers/Saas/BannerSection';
// import FeatureSection from 'containers/Saas/FeatureSection';
import VisitorSection from 'containers/Saas/VisitorSection';
import ServiceSection from 'containers/Saas/ServiceSection';
// import FaqSection from 'containers/Saas/FaqSection';
// import Footer from 'containers/Saas/Footer';
// import PricingSection from 'containers/Saas/PricingSection';
// import TrialSection from 'containers/Saas/TrialSection';
// import TimelineSection from 'containers/Saas/TimelineSection';
import TestimonialSection from 'containers/Saas/Testimonial';
// import PartnerSection from 'containers/Saas/PartnerSection';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import UpdateScreen from 'containers/Saas/UpdateScreen';
import StatsSection from 'containers/Saas/StatsSection';
import ContactSection from 'containers/Saas/Contact';
import Seo from 'components/seo';
import { Modal } from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';

const Saas = () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <Seo title="RadSmart | PACS Radiology Platform" />
        <Modal/>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <ServiceSection />
          <StatsSection />
          <UpdateScreen />
          {/* <PricingSection /> */}
          <VisitorSection />
          <TestimonialSection />
          {/* <FeatureSection /> */}
          {/* <PartnerSection /> */}
          {/* <TimelineSection /> */}
          {/* <FaqSection /> */}
          {/* <TrialSection /> */}
          <ContactSection />
          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Saas;
