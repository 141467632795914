import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Input from 'common/components/Input';
import Container from 'common/components/UI/Container';

import ContactFromWrapper from './contact.style';
import Fade from 'react-reveal/Fade';
import { openModal, closeModal } from '@redq/reuse-modal';
import styled from 'styled-components';
import Airtable from 'airtable';

const ContactSuccessWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuccessIcon = styled.i`
  width: 25px;
  color: #29cf8a;
  font-size: 26px;
  margin-right: 16px; 
`;

const ContactSuccess = () => (
  <ContactSuccessWrapper>
    <SuccessIcon as="i" className="flaticon-conversation" />
    <Text mb={0} content="Our Sales representative will get in touch with you shortly." />
  </ContactSuccessWrapper>
);

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const base = new Airtable({apiKey: 'key99UZfxEuqshp49'}).base('appUR7qwtOhmygfuk');

const ContactSection = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  secHeading,
  secText,
  button,
  note,
}) => {
  const [showNameError, setShowNameError] = useState(false)
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const valiadate = () => {
    console.log(name, phone)
    let hasErrors = false;

    if (name === undefined || name === '') {
      setShowNameError(true);
      hasErrors = true;
    } else {
      setShowNameError(false);
    }

    if (phone === undefined || phone === '') {
      setShowPhoneError(true);
      hasErrors = true;
    } else {
      setShowPhoneError(false);
    }

    return hasErrors;
  }

  const showSuccess = () => {
    setName('');
    setPhone('')
    setEmail('');
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        width: '90%',
        height: '100px',
        animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
        animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
        transition: {
          mass: 1,
          tension: 180,
          friction: 26,
        },
      },
      component: ContactSuccess,
      componentProps: {},
      // closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  }

  const onSubmit = async () => {
    const hasErrors = valiadate();
    if (hasErrors) return;
    await base('Leads').create([{
      fields: {
        Name: name,
        Phone: phone,
        Email: email,
      }
    }]);
    showSuccess();
  }

  return (
    <Box {...sectionWrapper}>
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="CONTACT US" />
          <Heading
            {...secHeading}
            content="Are you Interested to try out RadSmart?"
          />
        </Box>
        <Box {...row}>
          <Box {...contactForm}>
            <ContactFromWrapper>
              <Input
                inputType="text"
                placeholder="Name"
                iconPosition="right"
                isMaterial={false}
                className="email_input"
                aria-label="name"
                value={name}
                onChange={(e) => {setName(e)}}
                onBlur={() => valiadate()}
              />
              <Fade bottom collapse when={showNameError}>
                <Text fontSize={1} color="danger" mt={-2} pl={1} pb={2} content="Name is required" />
              </Fade>
              <Input
                inputType="text"
                placeholder="Phone Number"
                iconPosition="right"
                isMaterial={false}
                className="email_input"
                aria-label="phone"
                value={phone}
                onChange={(e) => {if (e.match(/^\+?[0-9]*$/) == null) return; setPhone(e)}}
                onBlur={() => valiadate()}
              />
              <Fade bottom collapse when={showPhoneError}>
                <Text fontSize={1} color="danger" mt={-2} pl={1} pb={2} content="Phone is required" />
              </Fade>
              <Input
                inputType="email"
                placeholder="Email address (Optional)"
                iconPosition="right"
                isMaterial={false}
                className="email_input"
                aria-label="email"
                value={email}
                onChange={(e) => {setEmail(e)}}
              />
              <Button {...button} title="SEND MESSAGE" onClick={(e) => onSubmit()}/>
            </ContactFromWrapper>
            {/* <Text
              {...note}
              content="Note: Please call us at 12pm to 8am. otherwise our customer supporter will not  available to reach your call, but you can drop mail anytime."
            /> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['0px', '10px', '20px', '80px'],
    pb: ['0px', '0px', '0px', '0px', '80px'],
    bg: '#f9fbfd',
  },
  secTitleWrapper: {
    mb: ['45px', '50px', '60px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`,
  },
  note: {
    fontSize: ['13px', '14px', '15px', '15px', '15px'],
    color: '#5d646d',
    lineHeight: '1.87',
    textAlign: 'center',
  },
};

export default ContactSection;
