import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import {
  CounterWrapper,
  CounterItem,
} from './statsSection.style';

const counterItems = [
  {id: 1, amount: '200+', title: 'Customers'},
  {id: 2, amount: '500+', title: 'Installations'},
  {id: 3, amount: '1,00,000+', title: 'Studies Processed Anually'},
];

const StatsSection = ({ row, col }) => (
    <Box bg="#fafbff" py={72}>
      <CounterWrapper>
        {counterItems.map((item) => (
          <CounterItem key={`counter_key${item.id}`}>
            <Heading as="h3" content={item.amount} />
            <Text content={item.title} />
          </CounterItem>
        ))}
      </CounterWrapper>
    </Box>
);

// StatsSection style props
StatsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
};

// StatsSection default style
StatsSection.defaultProps = {
  // StatsSection row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // StatsSection col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px',
  },
};


export default StatsSection;